import React, { useState } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaTelegram, FaYoutube } from 'react-icons/fa';
import { AiOutlineTikTok } from 'react-icons/ai';
import styles from '../css/Footer.module.css';
import Image from 'next/image'; // Import the Image component from next/image
import logo from '../public/logo_footee.webp'; // Import your image
import Link from 'next/link';
import { toast } from 'react-toastify';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://dailycoinmarket.com/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, category: 'simple' }),
      });

      if (response.ok) {
        setIsSubscribed(true);
        setEmail('');
        toast.success('Subscription successful');
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.error || 'Subscription failed';
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error during subscription');
    } finally {
      setShowToast(true);
    }
  };

  return (
    <div className={styles.footer}>
      <div className={styles.newsletterSignup}>
        <div className={styles.textContainer}>
          <p className={styles.largeText}>Latest crypto news in your inbox</p>
          <p className={styles.smallText}>Get the newsletter directly to your inbox</p>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email..."
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" disabled={isSubscribed}>
            {isSubscribed ? 'Subscribed' : 'Subscribe'}
          </button>
        </form>
      </div>

      <div className={styles.footerContent}>
        <div className={styles.logo}>
          <Link href="/">
            <Image src={logo} alt="DailyCoinMarket Logo" width={150} height={50} />
          </Link>
        </div>
        <div className={styles.linksContainer}>
          <div className={styles.linksColumn}>
            <h4>Company</h4>
            <Link href="/about-us">About Us</Link>
            <Link href="/contact">Contact Us</Link>
            <Link href="/advertise">Advertise</Link>
            <Link href="/terms">Terms of Use</Link>
            <Link href="/privacy">Privacy Policy</Link>
          </div>
          <div className={styles.linksColumn}>
            <h4>Popular Pages</h4>
            <Link href="/pump-coin/Coins">Pump Coins</Link>
            <Link href="/news">Latest News</Link>
            <Link href="/release/Coins">Released Coins</Link>
            <Link href="/all-analysis">Daily Analysis</Link>
            <Link href="/all-trading-signal">Trading Signals</Link>
          </div>
          <div className={styles.linksColumn}>
            <h4>Resources</h4>
            <Link href="/prediction">Blogs</Link>
            <Link href="/faq">FAQ</Link>
            <Link href="/support">Support</Link>
          </div>
        </div>
        <div className={styles.socialMedia}>
          <h4>Follow Us</h4>
          <div className={styles.icons}>
            <Link href="https://www.facebook.com/people/Daily-Coin-Market/61562279513612" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FaFacebook /></Link>
            <Link href="https://x.com/DailyCoinMarket" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><FaTwitter /></Link>
            <Link href="https://www.instagram.com/dailycoinsmarket" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagram /></Link>
            <Link href="https://www.tiktok.com/@dailycoinmarket" target="_blank" rel="noopener noreferrer" aria-label="TikTok"><AiOutlineTikTok /></Link>
            <Link href="https://t.me/dailycoinmarket" target="_blank" rel="noopener noreferrer" aria-label="Telegram"><FaTelegram /></Link>
            <Link href="https://www.youtube.com/@DailyCoinMarket" target="_blank" rel="noopener noreferrer" aria-label="YouTube"><FaYoutube /></Link>
          </div>
        </div>
      </div>

      <div className={styles.copyright}>
        <p>© 2024 DailyCoinMarket. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
