import React, { useState, useEffect, useRef } from 'react';
import styles from '../css/NavBar.module.css'; // Adjust the path as needed
import Image from 'next/image';
import logo from '../public/logo.webp';
import logo2 from '../public/logo192.webp';
import Link from 'next/link';
import { useRouter } from 'next/router';

const Navbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const searchBarRef = useRef(null);
  const router = useRouter();

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 0) {
      try {
        const response = await fetch(`https://dailycoinmarket.com/search?query=${value}`);
        const data = await response.json();
        const processedData = data.map((item) => ({
          ...item,
          images: JSON.parse(item.images),
        }));
        setSuggestions(processedData);
      } catch (error) {
        console.error('Error fetching search suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const truncateTitle = (title) => {
    const maxLength = 30;
    return title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm('');
    setSuggestions([]);

    switch (suggestion.source) {
      case 'newreleasedcoin':
        router.push(`/CoinReleased/${suggestion.slug}`);
        break;
      case 'news':
        router.push(`/news/${suggestion.slug}`);
        break;
      case 'prediction_news':
        router.push(`/prediction-news/${suggestion.slug}`);
        break;
      case 'pumped_news':
        router.push(`/pumped-news/${suggestion.slug}`);
        break;
      case 'pressrelease':
        router.push(`/press-release/${suggestion.slug}`);
        break;
      case 'dailyanalysis':
        router.push(`/analysis-blog/${suggestion.slug}`);
        break;
      case 'trading_signals':
        router.push(`/trading-blog/${suggestion.slug}`);
        break;
      default:
        console.error('Unknown source:', suggestion.source);
    }
  };

  const getImageSource = (suggestion) => {
    if (suggestion.images && suggestion.images.length > 0) {
      return suggestion.images[0];
    }
    if (suggestion.featured_image && suggestion.featured_image.length > 0) {
      return suggestion.featured_image[0];
    }
    return '';
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles['nav-container']}>
        <div className={styles.logo}>
          <Link href="/">
            <Image
              src={isMobile ? logo2 : logo}
              alt="DailyCoinMarket Logo"
              width={isMobile ? 40 : 350}
              height={isMobile ? 100 : 50}
            />
          </Link>
        </div>

        {!isMobile && (
          <ul className={styles['nav-links']}>
            <li
              className={styles['nav-item']}
              onMouseEnter={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <Link href="/news">
                <strong>News</strong>
                <i className={`${styles.arrow} ${styles.down}`}></i>
              </Link>
              {dropdownOpen && (
                <div className={styles['dropdown-menu']}>
                  <Link href="/news" className={styles['dropdown-item']}><strong>All News</strong></Link>
                  <Link href="/upcomingevents" className={styles['dropdown-item']}><strong>Upcoming Events</strong></Link>
                  <Link href="/recentevents" className={styles['dropdown-item']}><strong>Recent Events</strong></Link>
                </div>
              )}
            </li>
            <li className={styles['nav-item']}><Link href="/pump-coin/Coins"><strong>PumpCoin</strong></Link></li>
            <li
              className={styles['nav-item']}
              onMouseEnter={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <Link href="/release/Coins">
                <strong>Release</strong>
                <i className={`${styles.arrow} ${styles.down}`}></i>
              </Link>
              {dropdownOpen && (
                <div className={styles['dropdown-menu']}>
                  <Link href="/release/Coins" className={styles['dropdown-item']}><strong>Released Coin</strong></Link>
                  <Link href="/press-release" className={styles['dropdown-item']}><strong>Press Release</strong></Link>
                </div>
              )}
            </li>
            <li className={styles['nav-item']}><Link href="/all-analysis"><strong>Analysis</strong></Link></li>
            <li
              className={styles['nav-item']}
              onMouseEnter={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <Link href="/prediction">
                <strong>Prediction</strong>
                <i className={`${styles.arrow} ${styles.down}`}></i>
              </Link>
              {dropdownOpen && (
                <div className={styles['dropdown-menu']}>
                  <Link href="/all-trading-signal" className={styles['dropdown-item']}><strong>Trading Signals</strong></Link>
                  <Link href="/airdrops" className={styles['dropdown-item']}><strong>Airdrops</strong></Link>
                </div>
              )}
            </li>
          </ul>
        )}

        <div className={styles['search-bar']} ref={searchBarRef}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {suggestions.length > 0 && (
            <div className={styles['suggestions']}>
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className={styles['suggestion-item']}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <Image
                    src={getImageSource(suggestion)}
                    alt={suggestion.title}
                    className={styles['suggestion-image']}
                    width={50}
                    height={50}
                  />
                  <span>{truncateTitle(suggestion.title)}</span>
                </div>
              ))}
            </div>
          )}
        </div>

        {isMobile && (
          <div className={styles['menu-icon']} onClick={toggleSidebar}>
            &#9776;
          </div>
        )}
      </div>

      {isMobile && (
        <div className={`${styles.sidebar} ${sidebarOpen ? styles.open : ''}`}>
          <span className={styles['close-btn']} onClick={toggleSidebar}>
            &times;
          </span>
          <Link href="/" onClick={toggleSidebar}>Home</Link>
          <Link href="/news" onClick={toggleSidebar}>News</Link>
          <Link href="/pump-coin/Coins" onClick={toggleSidebar}>PumpCoin</Link>
          <Link href="/release/Coins" onClick={toggleSidebar}>Release</Link>
          <Link href="/all-analysis" onClick={toggleSidebar}>Analysis</Link>
          <Link href="/prediction" onClick={toggleSidebar}>Prediction</Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
